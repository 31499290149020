<script>
import { VxDataListItem } from "@/core-ui";
import { formatNumber } from "@/utils/phonenumber";
import { format, parseISO } from "date-fns";

export default {
  name: "BouncebackListItem",
  components: {
    VxDataListItem,
  },

  filters: {
    phonenumber(value) {
      return value ? formatNumber(value) : "";
    },
    date(value) {
      if (value) {
        if (typeof value === "string") {
          return format(parseISO(value), "MM/dd/yyyy");
        } else {
          return format(value, "MM/dd/yyyy");
        }
      } else {
        return "";
      }
    },
  },
  inheritAttrs: false,
  props: {
    coupon: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    showBounceback() {
      this.$emit("show", this.coupon);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    class="item rai-data"
    v-on="$listeners"
    @click.stop="showBounceback"
  >
    <template #desktop>
      <td class="name-cell">{{ coupon.name }}</td>
      <td class="phone-cell">{{ coupon.cellphone | phonenumber }}</td>
      <td class="transaction-cell">{{ coupon.transactionId }}</td>
      <td class="number-cell">
        <div class="ellipsis-outer">
          <div class="ellipsis-inner">
            {{ coupon.number }}
          </div>
        </div>
      </td>
      <td class="store-cell">{{ coupon.storeName }}</td>
      <td class="created-cell">{{ coupon.createdAt | date }}</td>
    </template>

    <template #mobile>
      <v-list-item-content class="coupon-name item__content pt-0">
        <v-list-item-title>
          <span
            class="font-weight-bold coupon-name-text"
            v-text="coupon.name"
          />
        </v-list-item-title>
        <v-list-item-subtitle>
          <span class="font-italic text-caption">{{
            coupon.cellphone | phonenumber
          }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content class="item__content pt-0">
        <v-list-item-title>
          <span v-text="coupon.number" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-text="coupon.storeName" />
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <span
            class="font-italic text-caption"
            v-text="coupon.transactionId"
          />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text class="primary--text item__content">{{
        coupon.createdAt | date
      }}</v-list-item-action-text>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.coupon-name-text {
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
}

.coupon-name {
  max-width: 12ch;
}
.item {
  padding-top: 8px;
  max-width: 100vw;
}
.item__content {
  align-self: start;
  padding-bottom: 8px;
}
</style>
