<script>
import BouncebackListItem from "./BouncebackListItem.vue";
import Bus from "@/utils/events";
import { COUPONS_LIST_VIEW } from "./graphql/queries";

import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  ServerPaginationUtils,
} from "@/core-ui";

export default {
  name: "BouncebackDataList",
  components: {
    VxDataList,
    BouncebackListItem,
  },
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
    storeId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions();
    const defaultSortOption = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "transactionId",
      sortOrder: SORT_ORDER.DESC,
    });

    return {
      // TODO - see if this is needed as init data or not
      coupons: [],
      couponsLoading: 0,
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOption,
      serverPaginationRequest:
        PaginationOptionsUtils.buildDefaultServerPaginationRequest(
          defaultPaginationOptions,
          defaultSortOption
        ),
    };
  },
  apollo: {
    coupons: {
      query: COUPONS_LIST_VIEW,
      loadingKey: "couponsLoading",
      skip() {
        return !this.storeId;
      },
      variables() {
        return this.queryVariables;
      },
      update({ coupons = { edges: [] } }) {
        ServerPaginationUtils.updateOptionsFromServerData(
          this.paginationOptions,
          coupons
        );

        return coupons.edges.map(({ node }) => node);
      },
    },
  },
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return [
        {
          propertyPath: "name",
          label: this.$t("bounceback.app.bouncebackList.nameHeader"),
          sortable: false,
          cssClass: "name-cell",
        },
        {
          propertyPath: "cellphone",
          label: this.$t("bounceback.app.bouncebackList.cellphoneHeader"),
          sortable: false,
          cssClass: "phone-cell",
        },
        {
          propertyPath: "transactionId",
          label: this.$t("bounceback.app.bouncebackList.transactionIdHedaer"),
          sortable: true,
          cssClass: "transaction-cell",
        },
        {
          propertyPath: "number",
          label: this.$t("bounceback.app.bouncebackList.numberHeader"),
          sortable: true,
          cssClass: "number-cell",
        },
        {
          propertyPath: "storeName",
          label: this.$t("bounceback.app.bouncebackList.storeHeader"),
          sortable: false,
          cssClass: "store-cell",
        },
        {
          propertyPath: "createdAt",
          label: this.$t("bounceback.app.bouncebackList.dateHeader"),
          sortable: true,
          cssClass: "created-cell",
        },
      ];
    },
    queryVariables() {
      return {
        storeId: this.storeId,
        ...this.serverPaginationRequest,
        filter: this.parsedFilter.data,
      };
    },
  },
  watch: {
    // TODO - find a better way to do this
    parsedFilter(newFilterValue) {
      this.onPageUpdate(1);
    },
  },
  created() {
    const vm = this;

    // TODO - maybe use subscriptions here!
    Bus.$on("coupon-created", (_coupons) => {
      vm.$apollo.queries.coupons.refetch();
    });
  },
  methods: {
    selectCoupon(coupon) {
      this.$router.push({
        name: "bounceback_show",
        params: { id: coupon.id },
        preserveQuery: true,
      });
    },
    onShowMore() {
      if (this.isSmallScreen) {
        // When loading data from server, mobile is handled via load more
        let mobileServerPaginationRequest = {};
        ServerPaginationUtils.updatePage(
          this.paginationOptions,
          this.sortOptions,
          mobileServerPaginationRequest,
          this.paginationOptions.page + 1
        );
        ServerPaginationUtils.fetchMoreData(
          this.$apollo.queries,
          "coupons",
          "coupons",
          {
            ...this.queryVariables,
            ...mobileServerPaginationRequest,
          }
        );
      }
    },
    onPageUpdate(page) {
      ServerPaginationUtils.updatePage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        page
      );
    },
    onItemsPerPageUpdate(itemsPerPage) {
      ServerPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      ServerPaginationUtils.updateSort(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        header.propertyPath
      );
    },
  },
};
</script>

<template>
  <VxDataList
    class="bounceback-list"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    :loading="couponsLoading"
    :empty-data-text="$t('bounceback.app.bouncebackList.emptyData')"
    :data-loading-text="$t('bounceback.app.bouncebackList.loadingData')"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <BouncebackListItem
        v-for="coupon in coupons"
        :key="coupon.id"
        :coupon="coupon"
        @show="selectCoupon"
      />
    </template>
  </VxDataList>
</template>

<style lang="scss">
.bounceback-list {
  .name-cell {
    width: 250px;
  }

  @media only screen and (max-width: 1100px) {
    .name-cell {
      width: 150px;
    }
  }

  .phone-cell {
    width: 200px;
  }

  .transaction-cell {
    width: 120px;
  }

  .number-cell {
    /* THIS one has auto width */
  }

  .store-cell {
    width: 200px;
  }

  .created-cell {
    width: 150px;
  }
}
</style>
